.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.7rem 1rem 0.7rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 0.7rem;
  }
  .card-content-data {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    overflow: scroll;
    min-height: 50%;
    .main-heading1 {
      font-size: 1rem;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: 0px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 15px;
        margin-top: 0px;
      }
    }

    .subheading,
    .subheading2,
    .subheading3 {
      font-size: 0.9rem;

      color: #0c81a8;

      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 14px;
      }
    }

    .content1,
    .content2,
    .content3 {
      font-size: 0.8rem;

      font-weight: 300;

      color: #797979;
      @media screen and (max-width: 768px) {
        font-size: 10px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
  .card-buttons {
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 0.7rem;
    margin-right: 0.4rem;

    .detail-btns {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    button {
      width: max-content;
    }

    @media screen and (max-width: 480px) {
      margin: 0rem;
    }
    @media screen and (min-width: 480px ) and (max-width:1024px){
      flex-direction: column;
    }
    .Read-more {
      border: none;
      white-space: nowrap;
      background-color: #d5edf4;
      color: #0c81a8;
      height: 2rem;
      padding: 0.4rem;
      // width: 8rem;
      text-align: initial;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      flex-wrap: nowrap;
      text-decoration: none;
      font-size: 13px;
      width: max-content;
    }

    .product-overview {
      border: none;
      white-space: nowrap;
      height: 2rem;
      padding: 0.4rem;

      // width: 10rem;
      text-align: initial;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      flex-wrap: nowrap;
      text-decoration: none;
      font-size: 13px;
      background-color: #d5f4ed;
      color: #049573;
      cursor: pointer;
    }

    .add-to-cart {
      white-space: nowrap;
      height: 2rem;
      padding: 0.4rem;

      // width: 10rem;
      text-align: initial;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      flex-wrap: nowrap;
      text-decoration: none;
      font-size: 13px;
      border: 1px solid #0c81a8;
      background-color: #0c81a8;
      color: white;
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      .Read-more, .product-overview, .add-to-cart {
        font-size: 10px;
        padding: 0.3rem;
        height: 1.6rem;
        
        .arrow-forward {
          width: 10px;
          height: 10px;
        }

        img {
          width: 8px;
          height: 8px;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .Read-more, .product-overview, .add-to-cart {
        font-size: 12px;
        padding: 0.5rem;
        height: 1.8rem;

        .arrow-forward {
          width: 12px;
          height: 12px;
        }

        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
