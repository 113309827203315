.sectorContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 90vh;
    margin: 0;
    .manage-users{
        text-decoration: none;
    }
}
.cardContainer{
    background-color: #F5FDFF;
    border:1px solid #0C81A8;
    box-shadow: 4px 4px 4px 4px #0C81A81F;
    border-radius: 8px;
    padding:12px;
    height:35vh;
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    text-align: center;
    margin:12px;
    @media screen and (max-width: 480px) {
        width:auto;
        height:40vh
    }
    img{
        height: 12vh;
    }
    .sectorName{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        // line-height: 22px; /* 61.111% */
        padding:10px 10px 0px 10px;
    }
    .sectorDescription{
        color: #444;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        // line-height: 28px; /* 127.273% */
        padding:8px 10px 10px 10px;
    }
    .arrow{
        height: 2vh;
    }
    
}

