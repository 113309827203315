.accountsetting {
  width: 60%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  h1 {
    font-weight: 500;
    color: #0c81a8;
    margin-top: 10vh;
  }

  .account-setting-form {
    border: 1px solid #0c81a8;
    border-radius: 7px;
    width: 100%;

      .form-section {
        border-bottom: 1px solid #0c81a8;
        padding: 1.4rem 1.2rem;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-block-start: 0px;
          margin-block-end: 0px;
          gap: 0.5rem;

          li {
            list-style-type: disc;
            font-size: 12px;
            @media screen and (max-width: 768px) {
              font-size: 11px;
            }
          }
        }

        .content {
          display: flex;
          justify-content: space-between;

          .eye-img {
            cursor: pointer;
            float: right;
            width: 18px;
            height: 18px;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        .main-content {
          width: 80%;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }

        .expand {
          width: 100%;
        }

        &:last-child {
          border-bottom: none;
        }

        .btn-container {
          display: flex;
          justify-content: flex-end;
          margin-top: 1rem;
        }

        button {
          padding: 0.2rem 0.5rem;
          border-radius: 4px;
          border: none;
          background-color: #0c81a8;
          color: #fff;
          cursor: pointer;
          width: max-content;
        }

        button:disabled {
          cursor: not-allowed;
          opacity: 0.6; 
        }

        .cancel-btn {
          background-color: #828282;
          color: #fff;
          margin-right: 0.4rem;
        }

        .edit-btn {
            border: none;
            margin-left: 3vw;
            background-color: #0c81a8;
            color: #fff;
            border-radius: 0.3rem;
            display: inline-flex;
            align-items: center;
            height: 32px;
            gap: 0.2rem;
            padding: 0 0.6rem;
            cursor: pointer;
            vertical-align: bottom;
            align-self: flex-end;

            @media screen and (max-width: 768px) {
              margin-left: auto;
            }
        }

        .password-edit-btn {
          margin-left: 0;
        }

        .names-edit-btn {
          align-self: flex-start;
          margin-top: 1.5rem;

          @media screen and (max-width: 768px) {
            margin-top: 0;
          }
        }

        .pass-field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.6rem;

          input {
            width: 74%;

          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;

            input {
              margin-top: 0.2rem;
              width: 100%;
            }
          }
        }

        .pass-title {
          color: #4A4A4A;
          font-size: 16px;
        }

      }

      h3 {
        margin: 0;
        font-weight: 500;
        color: #4a4a4a;
        font-size: 17px;
        margin-bottom: 0.2rem;
      }

      input {
        width: 100%;
        border: none;
        background-color: #e8f6fd;
        border-radius: 0.3rem;
        height: 3vh;
        padding: 1rem 0.8rem;
        outline: none;

        &:focus {
          outline: auto;
          outline-color: #0c81a8;
        }
      }
  }

  .err-text {
    font-size: 12px;
    color: #FF6347;
  }
}
