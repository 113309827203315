@import "../../../designSystem/common/common";

.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;

  .access-control-header-btn {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
}

.show {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 10vw;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.subShow {
  position: absolute;
  display: flex;
  left: 10vw;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.subdropdown {
  padding: 0px;
  margin: 0px;
}
.notShow {
  display: none;
}
.notSubShow {
  display: none;
}
.rightHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .search-container {
    display: flex;
  }

  .search-input {
    position: relative;
    input {
      padding: 4px;
      padding-left: 30px;
      border: 1px solid #ececeb;
      border-radius: 5px;

      &:focus {
        outline: auto;
        outline-color: #0c81a8;
      }
    }

    .search-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.4rem;
      color: #fff;
      background-color: #0b80a8;
      border-radius: 4px;
      max-width: calc(100% - 40px);
      padding: 2px 4px;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      font-size: 12px;

      .search-value-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cross-mark {
      position: relative;
      width: 12px;
      height: 12px;
      padding: 4px;
      cursor: pointer;
    }

    .cross-mark::before,
    .cross-mark::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .cross-mark::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .search-icon {
    position: absolute;
    top: 58%;
    left: 8px;
    transform: translateY(-50%);
    color: #808080;
  }

  .filter-container {
    position: relative;
    border-radius: 4px;
    background: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #ececeb;
    color: $textLight1Color;

    .filter-button {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      padding: 0.2rem 1rem;
      cursor: pointer;
    }

    .filter-bob {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $primaryDark1TextColor;
    }

    .popover-content {
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 260px;
      padding: 0.8rem;
      
      .filter-popover-btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.2rem;
        margin-top: 0.2rem;

        .card-button {
          font-size: 0.6rem;
        }
      }

      .filter-light-btn {
        background-color: #fff;
        color: #595959;
        border: 1px solid #ccc;
      }
    }

    .popover-content label {
      display: block;
      margin-bottom: 4px;
    }

    .popover-content input[type="checkbox"] {
      margin-right: 8px;
    }
  }

  /* Style the search input */
  .filter {
    width: 100%;
    padding: 4px;
    padding-left: 30px; /* Set left padding to accommodate the icon */
    border: 1px solid #ececeb;
    border-radius: 5px;
  }

  /* Style the search icon */
  .filter-icon {
    position: absolute;
    top: 57%;
    left: 8px; /* Adjust the left position as needed */
    transform: translateY(-50%);
    color: #808080;
  }
}
.main-container {
  width: 100%;
  margin: 12px;
  border-radius: 4px;
  background-color: white;
}
.userDetailsTable th {
  background-color: #edfaff;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}
td {
  text-align: center;
  border-bottom: 1px solid #cecece;
}
tbody tr {
  border-bottom: 1px solid black;
}
th {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #595959;
}

button {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  cursor: pointer;
}
td {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #595959;
}
li {
  list-style-type: none;
  border-radius: 4px;
  padding: 1px;
  margin: 4px;
}
ul {
  padding-inline-start: 0px;
  margin: 12px;
}

.editButton {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2px;
  margin: 6px;
}
.userEditButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
