.container {
  background-image: url("../../../assets//svg//land.svg");
  padding:1rem;
  background-size: cover;
  height: 90vh;
  overflow-y: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  .content {
    display: flex;
    flex-direction: row;
    align-items:center;
    gap: 1rem;
    position: relative;
    z-index: 1000;

    h3 {
      font-weight: 500;
      font-size: x-large;
    }
    
      button {
        cursor:pointer;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        background-color: #0c81a8;
        color: white;
        border-radius: 0.2rem;
        border: none;
      }   
  }

  .sub-container {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 60%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 74%;
    svg{
      width:70vw;
    }
  }
}
