@import "../../../designSystem/common/common";

$logintextcolor: #9a9a9a;
$logintextweight: 400;
$logintextweightbold: 600;

.reset {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    padding: 0rem 1rem 1rem 1rem;
  }
  .company {
    display: flex;
    flex-direction: row;
    gap: 6px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1); /* Adding a bottom box shadow */
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      width: 100vw;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      .colorHeading {
        color: #0b80a8;
      }
      div {
        font-size: 0.8rem;
      }
      .mobileLogo{
        width:20vw;
      }
    }
  }

  .reset-image {
    height: 100vh;
    width: 50vw;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      display: inline-block;
      align-self: stretch;
      width: 24vw;
    }
  }

  .main-part {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    @media screen and (max-width: 1024px) {
      padding: 0px;
    }
    .heading {
      color: #333;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
        color: #0c81a8;
        span {
          color: black;
        }
      }
    }
    .body {
      width: 40vw;
      gap: 0.8rem;
      background: rgba(255, 255, 255, 0.65);
      padding: 0 2rem;
      color: $logintextcolor;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        width: 100vw;
        padding: 1rem 2rem;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 76vw;
      }

      .formPart {
        gap: 0.8rem;
        display: flex;
        flex-direction: column;

        .label {
          color: #666;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        input {
          border-radius: 4px;
          border: none;
          background: #f5f5f5;
          padding: 0.4rem 0.4rem;
          font-size: 0.875rem;
        }

        input::placeholder {
          font-size: 0.875rem;
          color: $logintextcolor;
        }

        .rest-form-part {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          width: 100%;
          .resetOtp {
            display: flex;
            flex-direction: row;
            @media screen and (max-width: 1024px) {
              flex-direction: column;
            }
          }
          .submitOtp {
            white-space: nowrap;
            color: white;

            border-radius: 4px;
            border: 1px solid #0c81a8;
            background: #0c81a8;
            color: #fff;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0.2rem 1rem;
            @media screen and (max-width: 1024px) {
              display: flex;
              align-self: center;
              margin-top: 1rem;
            }
          }
          input {
            width: 100%;
          }
        }

        .captcha-part {
          width: 100%;

          .captcha {
            flex: 1;
            width: 50%;

            input {
              font-size: 2rem;
              padding: 2rem 1rem;
              border: none;
              background: #f5f5f5;
            }

            input::placeholder {
              font-size: 2rem;
            }
          }

          .buttonPart {
            flex: 1;
            text-align: center;
            align-self: flex-start;

            button {
              font-size: 0.9rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.error {
  color: red;
  font-size: 14px;
}
.subheading {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0px;

  span {
    color: #0c81a8;

    .signup-link {
      text-decoration: none;
      color: #0c81a8;
    }
  }
}
.captcha-part {
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
