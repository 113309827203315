@import "../../../../designSystem/colors";

.breadcrumb-container {
  display: flex;
  flex-direction: row;
  .breadcrumb {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0rem 1rem 0rem 1rem;
    a {
      text-decoration: none;
    }
  }
}

.parentModule {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 90vh;
  padding-top: 3rem;
  .headingModule {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 3rem;
    letter-spacing: 0em;
    text-align: center;
    color:$text-color;
    padding: 12px;
    @media screen and (max-width:768px) {
      
      font-size: 32px;
    }
  }

  .subHeadingModule {
    color: $sub-heading;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    @media screen and (max-width:480px) {
        display: none;
    
      }
  }

  .cardDiv {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 3vh;
      column-gap: 1.4vw;
      padding: 0 2rem;
      margin-top: 3rem;

      >div {
        flex-basis: 15%;
      }

      @media screen and (max-width: 768px) {

        padding: 0 0.4rem;
        column-gap: 2.4vh;

        >div {
          flex-basis: 46%;
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        >div {
          flex-basis: 40%;
        }
      }

    @media screen and (max-width:480px) {
      overflow-x: clip;
    }
  }
}
