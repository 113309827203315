.MobileHeader {
  height: 10vh;
  // width: 119%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mobileheaderleft {
    display: flex;
    .mobileLogo {
      width: 22vw;
    }
    .logo {
      display: flex;
      align-items: center;
      img {
        padding-left: 12px;
      }
    }
    .mobileheadermiddle {
      display: flex;
      align-items: center;
      flex-direction: row;

      .sustain {
        font-weight: 500;
        padding-left: 0.3rem;
        font-size: 12px;
        color: #0b80a8;
      }
      .market {
        font-weight: 500;
        padding-left: 0.2rem;
        font-size: 12px;
      }
    }
  }

  .mobileheaderright {
    .burger-menu {
      padding: 15px;
      .hamburger {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .cross-btn {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #0b80a8;
          position: relative;
          cursor: pointer;
        }

        .cross-btn::before,
        .cross-btn::after {
          content: "";
          position: absolute;
          width: 60%;
          height: 2px;
          background-color: #0b80a8;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .cross-btn::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        .cross-btn::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        .bar {
          width: 25px;
          height: 2px;
          background-color: #0b80a8;
          margin: 3px 0;
          transition: 0.4s;
        }

        &.open {
          .bar:nth-child(1) {
            transform: rotate(-50deg) translate(-7px, 5px);
          }

          .bar:nth-child(2) {
            opacity: 0;
          }

          .bar:nth-child(3) {
            transform: rotate(50deg) translate(-5px, -5px);
          }
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        width: max-content;
        right: 0;
        height: 100vh;
        position: absolute;
        top: 10vh;
        background-color: #fff;
        height: auto;
        z-index: 100000;
        padding: 20px;
        box-shadow: inset 0 3px 3px 0px rgba(0, 0, 0, 0.1),
          0 2px 5px rgba(0, 0, 0, 0.1);
          
        .menu-heading {
          .menu-content {
            display: flex;
            flex-direction: column;
            position: relative;

            .tagline {
              color: #999999;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              white-space: nowrap;
              text-decoration: none;
              margin-bottom: 2vh;

              &.active-link {
                color: #0c81a8;
              }
            }

            .header-dropdown {
              display: flex;
              flex-direction: row;
              align-items: center;
              position: relative;

              margin-top: -2vh;
              .module {
                font-weight: 400;
                font-size: 15px;
                color: #747474;
              }
              .arrow {
                cursor: pointer;
                margin-left: 2vw;
              }
            }
            .dropdown-content {
              margin: 0rem 1rem 2rem 1rem;

              .dropdown-items {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: 2vh;
                color: #747474;
                font-weight: 500;
                font-size: 15px;
              }
            }
          }
          .menu-items {
            .menu-dropdown-items {
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-top: 2vh;
              color: #747474;
              font-weight: 500;
              font-size: 15px;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }
          .language-selector {
            margin: 10px 0;

            select {
              background-color: #e8f6fd;
              border-radius: 0.3rem;
              display: flex;
              align-items: center;
              font-size: 0.9rem;
              color: #4d4d4d;
              font-weight: 500;
              border: none;
              padding: 6px 12px;
              height: 30px;
              width: 160px;
            }
            option {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
