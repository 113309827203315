.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.transparent-loader {
  width: 36%;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 60%;
    height: auto;
  }
}
