.orderHistory {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  height:90vh;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  
  .heading {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0C81A8;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }

    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  .subheadingrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      margin: 1.8rem 0;
    }

    .subheading {
      display: flex;
    flex-direction: row;
    border: 1px solid #55A7C5;
    border-radius: 4px;
    padding: 0.2rem;

      .sector {
        display: flex;

        .sectorName {
          white-space: nowrap;
          color: #555555;
          font-size: 0.8rem;
          cursor: pointer;
          padding: 0.4rem 0.6rem;
          border-radius: 4px;
        }

        .active-sector {
          color: #fff;
          background-color: #0C81A8;
        }
      }
    }
  }
  
  .orders {
    

    .order {
      border: 1px solid #55A7C5;
      border-radius: 4px;
      padding: 1rem;
      font-family: Poppins;
      margin-bottom: 1.6rem;

      .top-details-container, .bottom-details-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-text {
          color: #474747;
        }

        @media screen and (max-width: 768px) {
          font-size: 13px;
          flex-direction: column;
        }
      }

      .modules-container {
        display: flex;
        flex-wrap: wrap;
        margin: 1.2rem 0;
        gap: 0.8rem;
      }

      .module-details {
        white-space: nowrap;
        border-radius: 6px;
        border: 1px solid #DBDCE2;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
        flex-basis: 15.5%;

        @media screen and (max-width: 768px) {
          font-size: 0.6rem;
          flex-basis: 48%;
        }

        @media screen and (max-width: 480px) {
          font-size: 0.6rem;
          flex-basis: 100%;
        }

        .plan-details {
          margin-top: 0.2rem;
          color: #717171;
        }
      }

      .pricing-details {
        display: flex;
        flex-wrap: wrap;
        width: 36vw;
        margin-left: auto;
        justify-content: flex-end;
        font-size: 0.8rem;
        padding: 0.6rem 0;
        margin-bottom: 0.8rem;
        border-bottom: 1px solid #CACACA;
        row-gap: 0.4rem;
        column-gap: 1.4rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          font-size: 0.6rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 40%;
        }

        .module-price-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-basis: calc(50% - 0.7rem);

          @media screen and (max-width: 768px) {
            flex-basis: 80%;
          }

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            flex-basis: 100%;
          }
        }
      }

      .bottom-details-container {
        .value {
          color: #2B822E;
        }
      }
    }
  }

  .contact, .contact-bottom{
    button{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      background-color: #0C81A8;
      letter-spacing: 0em;
      color: white;
      padding: 0.4rem 0.6rem;
      border-radius: 0.3rem;
      border:none;
    }
  }

  .contact {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .contact-bottom {
    display: none;

    @media screen and (max-width: 1024px) {
      text-align: center;
      display: block;
      padding-bottom: 20px;
    }
  }
}
