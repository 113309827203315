.contact {
    padding-left: 1.6rem;
    h2 {
      font-size: 1.4rem;
    }
    .contact-container {
      background-color: white;
      width: 98%;
      height: 50%;
      border-radius: 0.5rem;
      padding: 1rem;
      h3 {
        font-size: 1rem;
        font-weight: 500;
        color: #4a4a4a;
      }
      .dropdown {
        padding-left: 1rem;
        width: 100%;
        height: 6vh;
        background-color: #e8f6fd;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        color: #4d4d4d;
        font-weight: 500;
        border: none;
      }
      .message-container {
        padding-left: 1rem;
        width: 100%;
        height: 31vh;
        background-color: #e8f6fd;
        display: flex;
        font-size: 0.9rem;
        color: #4d4d4d;
        border-radius: 0.3rem;
        padding-top: 1rem;
        font-weight: 500;
        border: none;
      }
      .submit-form {
        display: flex;
        justify-content: end;
        padding-top: 1rem;
        button {
          background-color: #0b80a8;
          color: #fff;
          border: none;
          padding: 0.5rem 2rem 0.5rem 2rem;
          border-radius: 0.3rem;
        }
      }
    }
  }