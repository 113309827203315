.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    border-bottom: 1px solid #EAEAEA;
}

.left-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 40vw;

    img {
        padding-left: 1rem;
    }

    @media screen and (min-width: 580px) and (max-width: 900px) {
        gap: 1rem;

        img {
            padding-left: 0.5rem;
            width: 100px;
        }
    }
}

.right-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right:1rem;
    gap:2rem;
    .globe{
        position: relative;
        cursor: pointer;

        .language-select {
            position: absolute;
            padding: 10px 5px;
            font-size: 12px;
            top: 120%;
            left: -50%;
            background-color: #f9f9f9;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            display: flex;
            border-radius: 4px;
            cursor: pointer;

            img {
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    .userProfile{
        display: flex;
        .userImage{
            // background-color: purple;
            width:40px;
            height:40px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .userName{
                color:white;
            }

        }
    }

    @media screen and (min-width: 580px) and (max-width: 900px) {
        gap: 1rem;
        padding-right: 0.5rem;

        .userProfile {
            .userImage {
                height: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }

        

        button {
            font-size: 12px;
        }
    }
}

.tagline {
    color: #999999;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    white-space: nowrap;
    text-decoration: none;

    &.active-link {
        color: #0C81A8;
    }

    @media screen and (min-width: 580px) and (max-width: 900px) {
        font-size: 12px;
    }
}

.request-button {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #0C81A8;
    border: none;
    color: white;
    white-space: nowrap;
}

.industry-dropdown {
    position: relative;
    display: inline-block;

    span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        color: #999999;

        .drop-down {
            padding-left: 6px;
            padding-right: 12px;
            height: 8px;
        }
    }
}

.industry-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}

.industry-dropdown:hover .industry-content {
    display: block;
}

.product-dropdown {
    position: relative;
    display: inline-block;
    z-index: 1999;

    span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        color: #999999;
        white-space: nowrap;
        .drop-down {
            padding-left: 6px;
            padding-right: 12px;
            height: 8px;
        }

        @media screen and (min-width: 580px) and (max-width: 900px) {
            font-size: 12px;

            .drop-down {
                width: auto;
                padding-left: 0;
                height: 6px;
            }
        }
    }
    
}

.product-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    width: fit-content !important;

    @media screen and (min-width: 580px) and (max-width: 900px) {
        font-size: 12px;
    }
}

.product-dropdown:hover .product-content {
    display: block;

}

.industry-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
        white-space: nowrap;
        padding: 10px;
        display: flex;
        align-items: center
    }

    img {
        padding: 5px;
    }
}

.link {
    display: flex;
    text-decoration: none;
    align-items: center;
    width: 100%;
}

.product-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    min-width: fit-content;
    width: 20vw;

    li {
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    li:hover {
        background-color: #f0f0f0;
    }

    img {
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    @media screen and (min-width: 580px) and (max-width: 900px) {
        width: unset;
        max-width: fit-content;

        img {
            width: 30px;
            height: 30px;
        }
    }
}