.empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
    
    img  {
        width: 60px;
        height: auto;
    }

    p {
        font-size: 14px;
        color: #808080;
        font-weight: 500;
        margin: unset;
        margin-top: 1.2rem;
    }

    .sub-text {
        font-size: 12px;
        margin-top: 0.3rem;
        font-weight: 400;
        color: #b3b3b3;
    }
}