.productplanContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    padding:10px;
    
}
.rightcolumncards{
    display: flex;
    .moduleFinance{
        margin: 20px 0px 20px 0px;
    }
    
}
.row{
    display: flex;
    flex-direction: row;
    width:100%;
}
.form-group{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-bottom: 0.4rem;

    .error-text {
        color: #f00;
    }
}
.headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:start;
    width:60%;
}

.addNewUserPopup{
        position: absolute;
        z-index:2;
        width: 36rem;
        background-color: white;
        left: 25rem;
        top: 15%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); 
        .addUserButton{
            display: flex;
            justify-content: flex-end;
            margin:4px;
            margin-top: 0.8rem !important;
        }
        .newUserForm{
            width:100%;
            .label{
                color: #666;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left:4px;
            }
            input {
               
                border-radius: 4px;
                border: none;
                background: #F5F5F5;
                padding: 0.4rem 0.6rem;
                font-size: 0.875rem;
                margin: 4px;
            }
            select{
                border-radius: 4px;
                border: none;
                background: #F5F5F5;
                padding: 0.4rem 0.6rem;
                font-size: 0.875rem;
                margin: 4px;
            }
            input::placeholder {
                font-size: 0.875rem;
            }
            .moduleHeader{
                margin-left:4px;
            }
            .addModuleButton{
                margin:4px;
            }
            
        }
        .popUpHeader{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding:12px;

            .heading {
                font-family: Helvetica;
                font-size: 16px;
                font-weight: 700;
                padding: 0.6rem 0rem 0.6rem 0rem;
          
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
              }
        }
      .popup-content{
        .popup-closeButton {
                border: none;
                text-align: left;
                // background-color: white;
                border-radius: 4rem;
        
                .popup-cross {
                    img {
                        margin-top: 0.3rem;
                    }
                }
            }
.popup-content-video{
    margin-left: 0rem;
        margin-top: -2rem;
}
      }
}

.popup-closeButton {
    border: none;
   background-color: rgba(255,255,255,0);
   
}
.image{
    height:20px;
    align-items: start;
}