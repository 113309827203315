.bouncing-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  
  .bouncing-loader > div {
    width: 14px;
    height: 14px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #2095BC;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-10px);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .bouncing-loader > div:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  /* Add additional dots and adjust animation delays as needed */
  