.productplanContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: 10px;

  .cross-mark {
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .cross-mark::before,
  .cross-mark::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    margin-top: 0.2rem;
    background-color: #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .cross-mark::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .error-text {
    color: #f00;
    font-size: 12px;
    margin: 4px 2px;
  }
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 60%;
}

.addNewUserPopup {
  position: absolute;
  border-radius: 10px;
  z-index: 2;
  width: 36rem;
  background-color: white;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  .addUserButton {
    display: flex;
    justify-content: flex-end;
    margin: 4px;
  }
  .newEmployeeUserForm {
    width: 100%;
    .label {
      color: #666;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 4px;
    }
    input {
      border-radius: 4px;
      border: none;
      background: #E8F6FD;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;
      margin: 4px;
    }
    select {
      border-radius: 4px;
      border: none;
      background: #E8F6FD;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;
      margin: 4px;
    }
    input::placeholder {
      font-size: 0.875rem;
    }
    .moduleHeader {
      margin-left: 4px;
    }
    .addModuleButton {
      margin: 4px;
      padding: 0.5rem 0rem 0.5rem 0rem;
    }
  }
  .popUpHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    .heading {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 700;
      padding: 0.6rem 0rem 0.6rem 0rem;

      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .popup-content {
    .popup-closeButton {
      border: none;
      text-align: left;
      // background-color: white;
      border-radius: 4rem;

      .popup-cross {
        img {
          margin-top: 0.3rem;
        }
      }
    }
    .popup-content-video {
      margin-left: 0rem;
      margin-top: -2rem;
    }
  }
}

.popup-closeButton {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}
.image {
  height: 20px;
  align-items: start;
}

