$textColor: #000;
$textLight1Color: #7E7E7E;
$primaryDark1TextColor: #0C81A8;
$buttonDark1BackgroundColor: #7B5ECA;
$buttonDark2BackgroundColor: #0C81A8;
$primaryButtonTextColor: #FFF;

//background
$background: rgba(255, 255, 255, 0.65);
$button_background_primary:#0c81a8;
$input-background:#f5f5f5;
$primary-background:#fff;
$background-screen-filter:#CCCCCC80;
$read-more-background:#d5edf4;
$selected-card-background:#d5f4ed;
$category-framework-background:#e4f8ff;

//text-color
$primary-text:#666;
$secondary-text:#000;
$error-text: #FF0000;
$perosna-sub-headingtext:#959595;
$icon-text:rgba(102, 102, 102, 0.8);
$text-color:#0c81a8;
$sub-heading:#6b6b6b;
$header-heading:#999999;
$selected-card-text:#049573;
$ternary-text:#797979;
$gray-text:#7E7E7E;
$heading-readmore:#b6b6b6;
$category-text:#808080;

//bordors colors
$primary-box-shadow:rgba(0, 0, 0, 0.12);
$secondary-box-shadow:rgba(0, 0, 0, 0.2);
$primary-border:#EAEAEA;
$billing-border:#dddddd;
$box-shadow:#0C81A81F;