.submission1 {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0.4rem;
  }

  .submission {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .infinity-container {
      height: 55vh;
      width: 100vw;

      @media screen and (max-width: 1024px) {
        height: 50vh;
        width: 100vw;
      }
    }

    .infinity-container svg {
      height: 40vh;
      width: 100vw;

      @media screen and (max-width: 1024px) {
        margin: 0rem;
        width: 100vw;
        padding: 1rem;
        height: 100%;
      }
    }

    .main-heading {
      color: #0c81a8;
      font-weight: 600;
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        padding: 0 1rem;
      }

      @media screen and (max-width: 1024px) {
        font-size: 23px;
      }
    }

    .sub-para1 {
      color: #999999;
      position: relative;
      padding-top: 1rem;
      font-size: 14px;
      text-align: center;

      @media screen and (max-width: 768px) {
        text-align: center;
        padding: 0 1rem;
        margin-bottom: 10px;
      }
    }

    .sub-para2 {
      color: #999999;
      position: relative;
      padding-bottom: 3rem;
      font-size: 14px;
      text-align: center;

      @media screen and (max-width: 768px) {
        text-align: center;
        padding: 0 1rem;
        padding-bottom: 0rem;
      }
    }
  }

  .buttons {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    gap: 2rem;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 2.2rem;
    }

    @media screen and (max-width: 1024px) {
      gap: 1rem;
      padding: 0px;
      justify-content: center;
      padding-bottom: 1rem;
    }

    .Next {
      width: 12rem;
      height: 2.5rem;
      border: 1px solid #0c81a8;
      border-radius: 0.5rem;
      color: #0c81a8;
      font-size: 1rem;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #0c81a8;
        color: #fff;
      }

      svg {
        margin-left: 1rem;
      }

    }
    .Previous {
      width: 12rem;
      height: 2.5rem;
      border: 1px solid #0c81a8;
      border-radius: 0.5rem;
      color: #0c81a8;
      font-size: 1rem;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #0c81a8;
        color: #fff;
      }

      svg {
        margin-right: 1rem;
      }
    }

    @media screen and (max-width: 768px) {
      .Previous, .Next {
        font-size: 0.8rem;
        width: max-content;      
        height: 2rem;
      }

      .Next {
        svg {
          margin-left: 0.5rem;
        }
      }
      .Previous {
        svg {
          margin-right: 0.5rem;
        } 
      }
    }
  }
}
