.ModuleCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 280px;
  }

  .cardContent {
    margin-top: auto;
    padding-bottom: 0.8rem;

    @media screen and (max-width: 768px) {
      padding-bottom: 0.4rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding-bottom: 2rem;
    }
  }

  .title {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: Poppins;
    letter-spacing: 0.012em;
    padding: 0px;
    text-align: center;
  }

  .content {
    color: #7e7e7e;
    text-align: center;
    font-size: 0.563rem;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 0.375rem;
    letter-spacing: 0.108px;
  }
}

.active {
  border: 2px solid #7b5eca;
}
