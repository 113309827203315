.productplanContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  background-repeat: no-repeat;
  padding: 1rem;
}
.rightcolumncards {
  display: flex;
  .moduleFinance {
    margin: 0px 0px 0px 0px;
  }
}

.row {
  display: flex;
  width: 100%;
  align-items:last baseline;
}

.or{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    color:#ACACAC;
    .line{
        height:1px;
        width:45%;
        background-color: #D1D1D1;
    }
}
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  .file-format {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    padding:0.2rem 0.5rem 0.2rem 0.5rem;
    color:#ACACAC;
    text-align: left;
  }
  .uploadContainer {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #f2f2f2;
    border-spacing: 30px;
    background-color: #ffffff;
    .fileInput{
      display: none;
    }
    .upload-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      .browseFile{
        cursor:pointer;
      }
    }
    .upload-text :nth-child(3) {
      color: #0b80a8;
    }
  }
  .download {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #f2f2f2;
    align-items: center;
    border-radius: 5px;
    margin: 0.5rem 0rem 0.5rem 0rem;
    .download-name {
      font-family: Poppins;
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      padding: 8px 12px 8px 12px;
    }
    .download-icon {
      display: flex;
      justify-content: center;
      padding: 8px 12px 8px 12px;
    }

    .file-loader {
      position: relative;
      overflow: hidden;
      opacity: 0.9;
      width: 20px;
      height: 30px;
      margin-left: auto;
      margin-right: 12px;
  }
  
  .file-loader:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -10px 0 0 -10px;
      border: 4px solid #e1e1e1;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      box-sizing: border-box;
      transform-origin: 50% 50%;
      height: 20px;
      width: 20px;
    }
    
    @keyframes spin {
      0% { 
        transform: rotate(0deg); 
      }
      100% { 
        transform: rotate(360deg); 
      }
    }
  }
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 60%;
}


.addNewUserPopup {
  position: absolute;
  border-radius: 10px;
  z-index: 2;
  width: 36rem;
  background-color: white;
  left: 25rem;
  top: 15%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  .addUserButton {
    display: flex;
    justify-content: flex-end;
    margin: 4px;
  }
  .newEmployeeUploadUserForm {
    width: 100%;
    .label {
      color: #666;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 4px;
    }
    input {
      border: 1px solid #D1D1D1;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;
      margin: 0.4rem 0rem 0.4rem 0rem;
    }
    select {
      border-radius: 4px;
      border: none;
      background: #e8f6fd;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;
      margin: 4px;
    }
    input::placeholder {
      font-size: 0.575rem;
    }
    .moduleHeader {
      margin-left: 4px;
    }
    .addModuleButton {
      margin: 4px;
      padding: 0.5rem 0rem 0.5rem 0rem;
    }
  }
  .popUpHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    .heading {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 700;
      padding: 0.6rem 0rem 0.6rem 0rem;

      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .popup-content {
    .popup-closeButton {
      border: none;
      text-align: left;
      // background-color: white;
      border-radius: 4rem;

      .popup-cross {
        img {
          margin-top: 0.3rem;
        }
      }
    }
    .popup-content-video {
      margin-left: 0rem;
      margin-top: -2rem;
    }
  }
}

.popup-closeButton {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}
.image {
  height: 20px;
  align-items: start;
}
