@import "../../../designSystem/common/common";

$logintextcolor: #9a9a9a;
$logintextweight: 400;
$logintextweightbold: 600;

.changePassword {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 1rem 1rem 1rem;
  }
  .company {
    display: flex;
    flex-direction: row;
    gap: 6px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1); /* Adding a bottom box shadow */
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      height: 10%;
      width: 100vw;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      .colorHeading {
        color: #0b80a8;
      }
      div {
        font-size: 1.1rem;
      }
    }
  }

  .changePassword-image {
    height: 100vh;
    width: 50vw;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      display: inline-block;
      align-self: stretch;
      width: 24vw;
    }
  }

  .main-part {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;

    @media screen and (max-width: 1024px) {
      padding: 0px;
    }
    
    .heading {
      color: #333;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
        color: #0c81a8;
        span {
          color: black;
        }
      }
    }
    .body {
      width: 40vw;
      gap: 0.8rem;
      background: rgba(255, 255, 255, 0.65);
      padding: 0 2rem;
      color: $logintextcolor;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 767px) {
        width: 100vw;
        padding: 1rem 2rem;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 76vw;
      }

      .formPart {
        gap: 0.8rem;
        display: flex;
        flex-direction: column;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-block-start: 0px;
          margin-block-end: 0px;
          column-gap: 0.8rem;

          li {
            margin: 2px 4px;
            list-style-type: disc;
            font-size: 12px;
            @media screen and (max-width: 768px) {
              font-size: 11px;
            }
          }
        }

        .rest-form-part {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          width: 100%;

          .hide-password {
            display: flex;
            justify-content: space-between;
          }

          .eye {
            display: flex;
            justify-content: end;
            align-items: center;
  
            button {
              background: white;
              border: none;
              .eye-icon {
                display: flex;
                font-size: 13px;
                color: rgba(102, 102, 102, 0.8);
              }
            }
          }

          input {
            width: 100%;
            border-radius: 4px;
            border: none;
            background: #f5f5f5;
            padding: 0.4rem 0.4rem;
            font-size: 0.875rem;
          }
          input::placeholder {
            font-size: 0.875rem;
            color: $logintextcolor;
          }
          .label {
            color: #666;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .password {
            background: #f5f5f5;
            border: none;
          }
        }
        .subheading {
          color: #333;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-top: 0px;

          span {
            color: #0c81a8;

            .changepassword-link {
              text-decoration: none;
              color: #0c81a8;
            }
          }
        }

        .captcha-part {
          width: 100%;

          .buttonPart {
            flex: 1;
            text-align: center;
            align-self: flex-start;

            .card-button {
              font-size: 0.9rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
