.accessdetail {
  top: 12%;
  right: 0;
  background-color: #fff;
  width: 23vw;
  height: 85vh;
  padding: 1rem;
  z-index: 2;
  overflow: scroll;
  position: absolute;
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-btn {
    cursor: pointer;
  }

  .acess-type-detail {
    padding-top: 1rem;
    display: flex;
    gap: 9.5rem;

    .access-type-button {
      border: none;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }
    .access-type-heading {
      font-size: 14px;
      white-space: nowrap;
      font-family: Poppins;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .horizontail {
    width: 100%;
    background-color: #7b7b7b;
    height: 1px;
  }

  .purchase-detail-data {
    display: flex;
    gap: 11rem;
    padding-top: 1rem;
    .purchase-access-button {
      border: none;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }
    .purchase-detail {
      font-size: 14px;
      white-space: nowrap;
      font-family: Poppins;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .container-name {
    font-weight: 600;
  }

  .accesstypecontent {
    h3 {
      font-weight: 600;
      font-size: 13px;
      color: #0c81a8;
      font-family: Poppins;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
    p {
      font-size: 12px;
      font-family: Poppins;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #4a4a4a;
    }
  }

  .purchased-access-container {
    .purchase-details-loading {
      font-size: 14px;
      text-align: center;
      color: #808080;
      margin-top: 2rem;
    }
  }

  .purchase-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    border: 1px solid #ddd;
    margin: unset;
    margin-top: 1rem;
  }

  .purchase-table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
  }
  .purchase-table th {
    border: 1px solid #ddd;
    text-align: left;
  }

  .purchase-table {
    .value {
      text-align: center;
    }
  }

  .moduleName {
    font-weight: 600;
    font-size: 13px;
    color: #0c81a8;
    margin: 0.5rem;
  }
  table {
    margin-bottom: 1rem;
    border-radius: 10px;
  }
}
