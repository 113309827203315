@import "../../../designSystem/common/common";

.userDetailsTable {
    width:100%;
    border-spacing: 0px;
}
.top-container{
    display: flex;
    flex-direction: row;
    width:100%;
    background-color: #EFEFEF;;
}
.sidebar{
    width:20%;
}
.tableHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:16px;

    .access-control-header-btn {
        display: flex;
        align-items: center;
        gap: 0.2rem;
    }

}

/* NestedDropdown.css */
.nested-dropdown-container {
    display: flex;
  }
  
  .dropdown-item {
    position: relative;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .submenu {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1;
  }
  
  .submenu-item {
    position: relative;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .options {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  /* Add your own styling as needed */
  
.rightHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap:20px;

      .search-container {
        display: flex;
        align-items: center;
        gap: 0.6rem;
      }
      
      .search-input {
        position: relative;

        input {
          padding: 4px;
          padding-left: 30px;
          border: 1px solid #ececeb;
          border-radius: 5px;
          height: 29px;
    
          &:focus {
            outline: auto;
            outline-color: #0c81a8;
          }
        }
    
        .search-value {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.4rem;
          color: #fff;
          background-color: #0b80a8;
          border-radius: 4px;
          max-width: calc(100% - 40px);
          padding: 2px 4px;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          font-size: 12px;
    
          .search-value-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
    
        .cross-mark {
          position: relative;
          width: 12px;
          height: 12px;
          padding: 4px;
          cursor: pointer;
        }
    
        .cross-mark::before,
        .cross-mark::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
    
        .cross-mark::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    
      .search-icon {
        position: absolute;
        top: 58%;
        left: 8px;
        transform: translateY(-50%);
        color: #808080;
      }
}
.leftHeader{
    display: flex;
    flex-direction: row;
    gap:20px;

    .header-text {
        color: $textLight1Color;
        font-size: 14px;
        font-weight: 500;
        margin: auto 0;
    }
}
.main-container{
    width:100%;
    margin:12px;
    border-radius: 4px;
    background-color: white;

}
.userDetailsTable th{
    background-color: #EDFAFF;
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    
}
td{
    text-align:center;
    border-bottom:1px solid #CECECE;
}
tbody tr{
    border-bottom:1px solid black;
}
.resendLink{
    cursor: pointer;
    color: #0C81A8;
    ;
}
th{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #595959;
}

button{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
}
td{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #595959;
    
    .button-loader {
        width: 20px;
        height: 30px;
        margin: 0 auto;
    }
    
}
li{
    list-style-type: none;
    border-radius: 4px;
    padding:1px;
    margin: 4px;
    
}
ul{
    padding-inline-start: 0px;
    margin:12px;
}

.editButton{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    padding:2px;
    margin:6px;
}
.userEditButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
}