.checkbox-select-container {
    display: flex;
    flex-direction: column;

    .checkbox-inner-container {
        margin-bottom: 1rem;
    }

    .checkbox-item {
        display: flex;
        gap: 0.4rem;

        input[type="checkbox"] {
            accent-color: #0c81a8;
        }

        .module-name {
            padding: 4px;
            cursor: pointer;
        }

        .module-name:hover {
            background-color: #fafafa;
        }
    }

    .access-types {
        margin-top: 0.5rem;
        margin-left: 1.8rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        .checkbox-item {
            gap: 0.3rem;

            input[type="checkbox"] {
                accent-color: #1a96c6;
            }
        }
    }
}