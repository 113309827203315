@import "../../../designSystem/common/common";

.account-verify {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  .company {
    display: flex;
    flex-direction: row;
    gap: 6px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1); /* Adding a bottom box shadow */
    @media screen and (min-width: 480px) {
      display: none;
    }
    @media screen and (max-width: 480px) {
      width: 100vw;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      .colorHeading {
        color: #0b80a8;
      }
      div {
        font-size: 0.8rem;
      }
      .mobileLogo {
        width: 20vw;
      }
    }
  }
  .login-image {
    height: 100vh;
    width: 50vw;
    object-fit: cover;
  }
  .verify-container {
    display: flex;
    justify-content: center;
    width: 50vw;
    @media screen and (max-width: 480px) {
      width:100vw;
    }
    .main {
      
      padding: 1.5rem;
      gap: 0.6rem;
      width: 36vw;
      @media screen and (max-width: 480px) {
        width:100vw;
      }
      background: rgba(255, 255, 255, 0.65);
      display: flex;
      flex-direction: column;

      .verify {
        color: #333;
        font-family: Poppins;
        font-size: 1.4rem;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        padding-top: 1rem;
      }

      
      .verification {
        color: #333;
        font-family: Poppins;
        font-size: 14px;
        padding-top: 2rem;
        font-style: normal;
        font-weight: 400;
        span {
          color: $primaryDark1TextColor;
          cursor: pointer;
        }
      }

      .codepart {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        color: #9a9a9a;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;

        .buttonPart{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        label {
          font-size: 14px;
        }
        input {
          border-radius: 4px;
          border: 1px solid #dbdbdb;
          background: #fff;
          padding: 0.4rem 0.6rem;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .error {
          color: red;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
